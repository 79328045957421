import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@og-pro/ui';

import { fieldsTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { pageBodyStyles, pageSectionContainerStyles } from './styles';
import { CreateCustomFieldSettings } from './Form/CreateCustomFieldSettings';
import { CreateCustomFieldPreview } from './Form/CreateCustomFieldPreview';

export const CreateCustomFieldForm = ({ fieldType }) => {
    return (
        <Box sx={pageBodyStyles}>
            <Grid container spacing={4}>
                <Grid item md={4} sm={12} sx={pageSectionContainerStyles}>
                    <Typography variant="h3">Preview</Typography>
                    <CreateCustomFieldPreview fieldType={fieldType} />
                </Grid>
                <Grid item md={8} sm={12} sx={pageSectionContainerStyles}>
                    <Typography variant="h3">The Question</Typography>
                    <CreateCustomFieldSettings fieldType={fieldType} />
                </Grid>
            </Grid>
        </Box>
    );
};

CreateCustomFieldForm.propTypes = {
    fieldType: PropTypes.oneOf(fieldsTypesArray).isRequired,
};
