import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import { fieldNames, requestTypeGroupSequenceFieldNames } from './constants';
import { insertReviewGroupPositions } from './helpers';
import { RequisitionGroupPositionsField } from './RequisitionGroupPositionsField';
import { getRequisitionGroupFormValues, getReviewSequenceOptions } from '../selectors';
import { SearchSelect } from '../../../../../components';
import { getReviewSequences } from '../../../../../selectors/govApp';

const { REVIEW_GROUP_POSITIONS, REVIEW_SEQUENCE, REQUEST_TYPE_GROUP_SEQUENCES } = fieldNames;
const { REVIEW_SEQUENCE_ID } = requestTypeGroupSequenceFieldNames;

export const RequisitionGroupSequenceSection = ({ change, disabled, showFormValidation }) => {
    const formValues = useSelector(getRequisitionGroupFormValues);
    const sequenceOptions = useSelector(getReviewSequenceOptions);
    const sequences = useSelector(getReviewSequences);

    const onSequenceChange = (sequenceId) => {
        const selectedSequence = sequences.find((sequence) => sequence.id === sequenceId);
        change(REVIEW_SEQUENCE, selectedSequence);

        const reviewGroupPositionFormValues = get(formValues, REVIEW_GROUP_POSITIONS) || [];
        const sequencePositions = insertReviewGroupPositions(
            reviewGroupPositionFormValues,
            selectedSequence
        );
        change(REVIEW_GROUP_POSITIONS, sequencePositions);
    };

    return (
        <>
            <Field
                component={SearchSelect}
                disabled={disabled}
                help="Sequence to use when routing requisitions in this group for approval"
                label="Approval Sequence"
                // REQUEST TYPES TODO: Remove hard coded reference to first index position when multiple request types can be added
                name={`${REQUEST_TYPE_GROUP_SEQUENCES}.0.${REVIEW_SEQUENCE_ID}`}
                onChange={onSequenceChange}
                options={sequenceOptions}
                placeholder="Select approval sequence"
                showValidation={showFormValidation}
            />
            <FieldArray
                change={change}
                component={RequisitionGroupPositionsField}
                disabled={disabled}
                name={REVIEW_GROUP_POSITIONS}
                showFormValidation={showFormValidation}
            />
        </>
    );
};

RequisitionGroupSequenceSection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
