import React from 'react';

import { Field } from 'redux-form';

import { RadioGroup } from '../../../../../components';

const ConnectedNumberOptions = () => {
    return (
        <Field
            component={RadioGroup}
            label="Type of Number *"
            name="typeOfNumber"
            options={[
                {
                    name: 'Integer',
                    value: 'integer',
                },
                {
                    name: 'Decimal',
                    value: 'decimal',
                },
            ]}
            showValidation
            useOpenGovStyle
        />
    );
};

export const NumberOptions = ConnectedNumberOptions;
