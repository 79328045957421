import { fieldTypesData } from './constants';

export const validate = (values, props) => {
    const { fieldType } = props;
    let errors = {};

    const fieldTypeValidateFn = fieldTypesData[fieldType]?.validateFn || (() => ({}));

    errors = {
        ...errors,
        ...fieldTypeValidateFn(values),
    };

    return errors;
};
