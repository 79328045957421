import React from 'react';
import PropTypes from 'prop-types';
import { fieldsTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { fieldTypesData } from '../Form/constants';

export const FieldTypePreview = ({ fieldType }) => {
    const FieldTypePreviewComponent = fieldTypesData[fieldType].previewComponent;

    if (!FieldTypePreviewComponent) {
        return null;
    }

    return <FieldTypePreviewComponent />;
};

FieldTypePreview.propTypes = {
    fieldType: PropTypes.oneOf(fieldsTypesArray).isRequired,
};
