import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Typography } from '@og-pro/ui';
import {
    fieldsDataTypes,
    fieldsTypesArray,
} from '@og-pro/shared-config/customFormService/customField';
import { useSelector } from 'react-redux';

import { omit } from 'lodash';

import { getCustomFieldsLibraryPath } from '../selectors';

export const CreateCustomFieldHeader = ({ fieldType, handleSubmit }) => {
    const params = useParams();
    const navigate = useNavigate();

    const customFieldsLibraryPath = useSelector((state) =>
        getCustomFieldsLibraryPath(state, { params })
    );

    function handleBackToLibraryClick() {
        navigate(customFieldsLibraryPath);
    }

    function omitPreviewFormValues(formValues) {
        // omit all the values of the formValues object that have the _PREVIEW prefix
        return omit(
            formValues,
            Object.keys(formValues).filter((key) => key.includes('_PREVIEW'))
        );
    }

    function handleCreateFieldClick(formValues) {
        // eslint-disable-next-line no-unused-vars
        const formData = omitPreviewFormValues(formValues);
        // TODO: implement
    }

    function handleCreateAndPublishFieldClick(formValues) {
        // eslint-disable-next-line no-unused-vars
        const formData = omitPreviewFormValues(formValues);
        // TODO: implement
    }

    return (
        <Box>
            <Box>
                <Button
                    color="secondary"
                    onClick={handleBackToLibraryClick}
                    qaTag="requisitionsAdminCustomFields-back"
                    startIcon={<ArrowBack />}
                    sx={{
                        padding: 0,
                    }}
                    variant="text"
                >
                    Back to Library of Custom Fields
                </Button>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="h2">
                        Create a New {fieldsDataTypes[fieldType].label} Field
                    </Typography>
                </Box>
                <Box display="flex" gap={1}>
                    <Button
                        color="primary"
                        onClick={handleSubmit(handleCreateAndPublishFieldClick)}
                        qaTag="requisitionsAdminCustomFields-createAndPublish"
                        variant="outlined"
                    >
                        Create & Publish Field
                    </Button>
                    <Button
                        onClick={handleSubmit(handleCreateFieldClick)}
                        qaTag="requisitionsAdminCustomFields-create"
                        variant="contained"
                    >
                        Create Field
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

CreateCustomFieldHeader.propTypes = {
    fieldType: PropTypes.oneOf(fieldsTypesArray).isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
