import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import { useEffect, useState } from 'react';

import { getUserJS } from '../../../../containers/selectors';
import { hideLoginModal, showLoginModal } from '../../../../actions/auth';
import { USE_OG_PLATFORM } from '../../../../constants';

export const useContextSwitcherOptions = () => {
    const user = useSelector(getUserJS);
    const [auth0Config, setAuth0Config] = useState();
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    useEffect(() => {
        let tokenRenewalTimeout;

        const fetchToken = () => {
            if (typeof window !== 'undefined') {
                getAccessTokenSilently({ cacheMode: 'on' })
                    .then((auth0token) => {
                        setAuth0Config({
                            auth0token,
                        });

                        const decodedToken = JSON.parse(atob(auth0token.split('.')[1])); // Decode the token
                        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
                        const currentTime = Date.now();

                        const timeUntilExpiration = expirationTime - currentTime - 60000; // 1 minute before expiration

                        if (timeUntilExpiration > 0) {
                            tokenRenewalTimeout = setTimeout(fetchToken, timeUntilExpiration);
                        }
                    })
                    .catch((error) => {
                        if (error.error === 'login_required') {
                            dispatch(
                                showLoginModal(
                                    'Your session has timed out. Please log in again to continue working.',
                                    () => dispatch(hideLoginModal()),
                                    { isSessionTimeout: true }
                                )
                            );
                        }
                    });
            }
        };

        if (USE_OG_PLATFORM && user.isPlatformAuthorized) {
            fetchToken();
        }

        return () => {
            if (tokenRenewalTimeout) {
                clearTimeout(tokenRenewalTimeout);
            }
        };
    }, [getAccessTokenSilently]);
    return {
        activeProductKey: 'procurement',
        auth0Config,
        baseUrl: process.env.OG_CONTROL_PANEL_URL ?? 'https://controlpanel.ogintegration.us',
        userUUID: user.platformUuid,
        activeEntityId: user.government.code,
        activeEntityIdType: 'BY_PROCUREMENT_ID',
    };
};
