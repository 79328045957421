import { ReduxFormTextField } from '@og-pro/ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

import { createCustomFieldInitialFieldsDict, formConfig } from '../Form/form';

const { NAME } = createCustomFieldInitialFieldsDict;

export const NumberPreview = () => {
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state)) || {};

    return (
        <Field
            component={ReduxFormTextField}
            disabled
            label={formValues[NAME] || 'Your title will go here'}
            name="number_PREVIEW"
            startIcon={<AttachMoneyOutlinedIcon />}
            sx={{
                width: '100%',
            }}
        />
    );
};
