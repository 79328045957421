import { listToDict } from '@og-pro/shared-config/helpers';

import { validate } from './validate';

const formName = 'requisitionsCreateCustomField';

export const formConfig = {
    form: formName,
    validate,
};

const createCustomFieldInitialFields = [
    'name',
    'shortTitle',
    'instructionsText',
    'instructionsMode',
];

export const createCustomFieldInitialFieldsDict = listToDict(createCustomFieldInitialFields);
