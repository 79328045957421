import React from 'react';
import { useParams } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { Box } from '@mui/material';

import { useSelector } from 'react-redux';

import { LoadingError, Main, NotFound, PageTitle } from '../../../../components';
import { CreateCustomFieldHeader } from './CreateCustomFieldHeader';

import { pageContainerStyles } from './styles';
import { isSystemAdminUser } from '../../../selectors';
import { hasRequisitionSubscription } from '../../../GovApp/selectors';
import { CreateCustomFieldForm } from './CreateCustomFieldForm';
import { formConfig } from './Form/form';

const ConnectedRequisitionsCreateCustomField = ({ handleSubmit }) => {
    const params = useParams();
    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const isSystemAdmin = useSelector(isSystemAdminUser);
    const hasRequisitions = useSelector(hasRequisitionSubscription);

    if (!enableCustomFields) {
        return <NotFound />;
    }

    if (!isSystemAdmin || !hasRequisitions) {
        return (
            <LoadingError error="You do not have permission to view this page" useOpenGovStyle />
        );
    }

    return (
        <Main>
            <PageTitle title="Create New Custom Field" />
            <Box sx={pageContainerStyles}>
                <CreateCustomFieldHeader fieldType={params.fieldType} handleSubmit={handleSubmit} />
                <CreateCustomFieldForm fieldType={params.fieldType} />
            </Box>
        </Main>
    );
};

ConnectedRequisitionsCreateCustomField.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export const RequisitionsCreateCustomField = reduxForm(formConfig)(
    ConnectedRequisitionsCreateCustomField
);
