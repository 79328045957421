import { createSelector } from 'reselect';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import {
    hasContractingSubscription,
    hasRequisitionOnlySubscription,
    hasRequisitionSubscription,
    hasSourcingSubscription,
} from '../../GovApp/selectors';

const {
    IS_BID_AMENDER,
    IS_BID_POSTER,
    IS_CONTRACT_ADMIN,
    IS_DEPARTMENT_BID_AMENDER,
    IS_DEPARTMENT_BID_POSTER,
    IS_DEPARTMENT_CONTRACT_ADMIN,
    IS_DEPARTMENT_EDITOR,
    IS_GLOBAL_EDITOR,
    IS_GLOBAL_VIEWER,
    IS_PROJECT_CREATOR,
    IS_REQUISITION_GLOBAL_EDITOR,
    IS_REQUISITION_GROUP_ADMIN,
    IS_RETENTION_ADMIN,
    IS_SYSTEM_ADMIN,
    IS_TEMPLATE_ADMIN,
    IS_VENDOR_ADMIN,
} = userRolePermissions;

export const getUserRolePermissionOptions = createSelector(
    [
        hasSourcingSubscription,
        hasContractingSubscription,
        hasRequisitionSubscription,
        hasRequisitionOnlySubscription,
    ],
    (hasSourcing, hasContracting, hasRequisition, hasRequisitionOnly) => {
        const bidAmenderHelp =
            'Can make updates to the contents of bids after they have been posted. Users without this permission can still create addendum and official notices. [Requires Global Editor Permission]';
        const departmentBidAmenderHelp =
            'Can make updates to the contents of bids within their department after they have been posted [Requires Department Editor Permission]';
        const bidPosterHelp =
            'Can create and post public solicitations [Requires Global Editor Permission]';
        const departmentBidPosterHelp =
            'Can create and post public solicitations within their department [Requires Department Editor Permission]';
        const projectCreatorHelp =
            'Can create a project without going through the Intake process and required for creating a contract document';
        const globalEditorHelp = 'Automatically receives edit ability for all projects';
        const departmentEditorHelp =
            'Automatically receives edit ability for all projects within their department';
        const globalViewerHelp = 'Can view all projects';
        const contractAdminHelp =
            'Can create and edit all contract records. Users without this permission cannot create contract records and can edit only those contract records where they have been given edit permission.';
        const departmentContractAdminHelp =
            'Can create and edit all contracts within their department.';
        const requisitionGroupAdminHelp =
            'Can create and edit Requisition Review Groups and Review Sequences';
        const requisitionGlobalEditorHelp =
            'Automatically receives view and edit ability for all requisitions';
        const retentionAdminHelp =
            'Receives access to the retention admin panel, can manage retention policies, apply policies to closed projects, and can purge records when the expiration date has passed.';
        const systemAdminHelp =
            'Receives access to the admin panel and can add new users to the system';
        const templateAdminHelp =
            "Receives access to the templates admin panel and can manage the organization's templates";
        const vendorAdminHelp =
            'Receives access to the vendor list admin, can search or invite vendors, and manage vendor lists';

        const availableResourceNames = hasContracting ? 'Projects & Contracts' : 'Projects';

        const generalGroup = {
            groupName: 'General Admin',
            permissions: [
                {
                    name: IS_SYSTEM_ADMIN,
                    label: 'System Admin',
                    help: systemAdminHelp,
                },
                {
                    name: IS_TEMPLATE_ADMIN,
                    label: 'Templates Admin',
                    help: templateAdminHelp,
                    hide: hasRequisitionOnly,
                },
                {
                    name: IS_VENDOR_ADMIN,
                    label: 'Vendor Admin',
                    help: vendorAdminHelp,
                    hide: !hasSourcing,
                },
                {
                    name: IS_RETENTION_ADMIN,
                    label: 'Retention Admin',
                    help: retentionAdminHelp,
                    hide: hasRequisitionOnly,
                },
            ],
        };

        const requisitionsGroup = {
            groupName: 'Requisitions',
            hide: !hasRequisition,
            permissions: [
                {
                    name: IS_REQUISITION_GROUP_ADMIN,
                    label: 'Requisition Group Admin',
                    help: requisitionGroupAdminHelp,
                    hide: !hasRequisition,
                },
                {
                    name: IS_REQUISITION_GLOBAL_EDITOR,
                    label: 'Requisition Global Editor',
                    help: requisitionGlobalEditorHelp,
                    hide: !hasRequisition,
                },
            ],
        };

        if (hasRequisitionOnly) {
            return [generalGroup, requisitionsGroup];
        }

        return [
            generalGroup,
            {
                groupName: `Global ${availableResourceNames}`,
                permissions: [
                    {
                        name: IS_PROJECT_CREATOR,
                        label: 'Project Creator',
                        help: projectCreatorHelp,
                    },
                    {
                        name: IS_GLOBAL_VIEWER,
                        label: 'Global Viewer',
                        help: globalViewerHelp,
                    },
                    {
                        name: IS_GLOBAL_EDITOR,
                        label: 'Global Editor',
                        help: globalEditorHelp,
                        onChange: (props) => (e, value) => {
                            if (!value) {
                                props.change(IS_BID_POSTER, false);
                                props.change(IS_BID_AMENDER, false);
                            }
                        },
                    },
                    {
                        name: IS_BID_POSTER,
                        label: 'Bid Poster',
                        help: bidPosterHelp,
                        hide: !hasSourcing,
                        disabledFn: (props) => !props.isGlobalEditorValue,
                    },
                    {
                        name: IS_BID_AMENDER,
                        label: 'Bid Amender',
                        help: bidAmenderHelp,
                        hide: !hasSourcing,
                        disabledFn: (props) => !props.isGlobalEditorValue,
                    },
                    {
                        name: IS_CONTRACT_ADMIN,
                        label: 'Contracts Admin',
                        help: contractAdminHelp,
                        hide: !hasContracting,
                    },
                ],
            },
            {
                groupName: `Departmental ${availableResourceNames}`,
                permissions: [
                    {
                        name: IS_DEPARTMENT_EDITOR,
                        label: 'Department Editor',
                        help: departmentEditorHelp,
                        onChange: (props) => (e, value) => {
                            if (!value) {
                                props.change(IS_DEPARTMENT_BID_POSTER, false);
                                props.change(IS_DEPARTMENT_BID_AMENDER, false);
                            }
                        },
                    },
                    {
                        name: IS_DEPARTMENT_BID_POSTER,
                        label: 'Department Bid Poster',
                        help: departmentBidPosterHelp,
                        hide: !hasSourcing,
                        disabledFn: (props) => !props.isDepartmentEditorValue,
                    },
                    {
                        name: IS_DEPARTMENT_BID_AMENDER,
                        label: 'Department Bid Amender',
                        help: departmentBidAmenderHelp,
                        hide: !hasSourcing,
                        disabledFn: (props) => !props.isDepartmentEditorValue,
                    },
                    {
                        name: IS_DEPARTMENT_CONTRACT_ADMIN,
                        label: 'Department Contracts Admin',
                        help: departmentContractAdminHelp,
                        hide: !hasContracting,
                    },
                ],
            },
            requisitionsGroup,
        ];
    }
);
