import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import React from 'react';

import { fieldsTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { FieldTypePreview } from '../FieldTypePreview';

export const CreateCustomFieldPreview = ({ fieldType }) => {
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
                    borderRadius: 1,
                    border: `1px solid ${capitalDesignTokens.semanticColors.background.actionLight}`,
                    padding: 3,
                }}
            >
                <FieldTypePreview fieldType={fieldType} />
            </Box>
        </Box>
    );
};

CreateCustomFieldPreview.propTypes = {
    fieldType: PropTypes.oneOf(fieldsTypesArray).isRequired,
};
