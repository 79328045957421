import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { projectStatusesDict } from '@og-pro/shared-config/projects';

import {
    getApprovalsListJS,
    getBaseProjectPath,
    getBuilderDisplayName,
    getReviewProjectJS,
    isBuilderEditor,
} from '../../../../selectors';
import { LoadingError } from '../../../../../../components';
import { getInvitedUsersJS, getUserJS } from '../../../../../selectors';
import { getIsApprovalComplete, getReviewStepApproverMap } from '../../../../Approvals/selectors';
import { ApprovalsDashboard } from '../../../../../../components/GovApp';
import {
    menuActionHandler,
    showInstructionsModal as showInstructions,
} from '../../../../../../actions/govProjects';
import { FINALIZE } from '../../../../../../constants/menuActions';
import {
    approvalReminder,
    removeApprover,
    removeReviewStep,
    showModal,
    addApprovers,
    updateApprovers,
} from '../../../../../../actions/approvals';

const { DRAFT, REVIEW } = projectStatusesDict;

export const ProjectCreateApprovals = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const addApproversError = useSelector((state) => state.approvals.addError);
    const addingApprovers = useSelector((state) => state.approvals.adding);
    const approvals = useSelector(getApprovalsListJS);
    const builderDisplayName = useSelector(getBuilderDisplayName);
    const loadApprovalsError = !!useSelector((state) => state.approvals.loadError);
    const user = useSelector(getUserJS);
    const project = useSelector(getReviewProjectJS);
    const inDraft = useSelector(
        (state) => state.govProjects.getIn(['selectedProject', 'status']) === DRAFT
    );
    const inReview = useSelector(
        (state) => state.govProjects.getIn(['selectedProject', 'status']) === REVIEW
    );
    const instructionType = 'approvals';
    const isApprovalComplete = useSelector(getIsApprovalComplete);
    const isEditor = useSelector(isBuilderEditor);
    const reviewStepApproversMap = useSelector(getReviewStepApproverMap);
    const users = useSelector(getInvitedUsersJS);
    const projectPath = getBaseProjectPath(null, { params });

    const styles = require('./index.scss');

    const handlePublish = () => {
        dispatch(menuActionHandler(FINALIZE, project));
    };

    const showInstructionsModal = () => {
        dispatch(
            showInstructions(instructionType, {
                isAssignedUser: false,
                isEditor,
            })
        );
    };

    const addApproversSubmitHandler = (formData) => {
        const { approverIds } = formData;
        const userIds = approverIds.map((oneUser) => oneUser.value);
        return dispatch(addApprovers(project.id, userIds));
    };

    const updateApproversSubmitHandler = (formData, reviewStepId) => {
        const { approverIds } = formData;
        const userIds = approverIds.map((oneUser) => oneUser.value);
        return dispatch(updateApprovers(project.id, userIds, reviewStepId));
    };

    if (loadApprovalsError) {
        return <LoadingError />;
    }

    return (
        <Box className={styles.container}>
            <ApprovalsDashboard
                addApproversError={addApproversError}
                addApproversHandler={addApproversSubmitHandler}
                addingApprovers={addingApprovers}
                approvals={approvals}
                builderDisplayName={builderDisplayName}
                customStyles={styles}
                inDraft={inDraft}
                inReview={inReview}
                isApprovalComplete={isApprovalComplete}
                isEditor={isEditor}
                isPreviewMode={false}
                project={project}
                publishHandler={handlePublish}
                reminderHandler={(approvalId, reReview, comment, reviewStepId) =>
                    dispatch(approvalReminder(approvalId, reReview, comment, reviewStepId))
                }
                removeApproverHandler={(approvalId, reviewStepId) =>
                    dispatch(removeApprover(approvalId, reviewStepId))
                }
                removeReviewStepHandler={(projectId, reviewStepId) =>
                    dispatch(removeReviewStep(projectId, reviewStepId))
                }
                reviewStepApproversMap={reviewStepApproversMap}
                showInstructionsModal={showInstructionsModal}
                showModal={(approvalType, data) => dispatch(showModal(approvalType, data))}
                updateApproversHandler={updateApproversSubmitHandler}
                user={user}
                users={users}
                writingPath={`${projectPath}/builder/create/review-checklist`}
            />
        </Box>
    );
};
