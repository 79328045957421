import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import { Button } from '../../../../components/Button/Button';
import { AdminUserListButton } from '../../../../components';

const { IS_CONTRACT_ADMIN, IS_PROJECT_CREATOR } = userRolePermissions;

export class ModuleSelect extends PureComponent {
    static propTypes = {
        clickHandler: PropTypes.func.isRequired,
        hasContracting: PropTypes.bool.isRequired,
        hasRequisition: PropTypes.bool.isRequired,
        hasIntake: PropTypes.bool.isRequired,
        hasProject: PropTypes.bool.isRequired,
        isContractAdmin: PropTypes.bool.isRequired,
        isProjectCreator: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./shared.scss');
    }

    handleContractClick = () => {
        const { clickHandler } = this.props;

        clickHandler({ isContracts: true });
    };

    handlePurchaseClick = () => {
        const { clickHandler } = this.props;

        clickHandler();
    };

    handleIntakeClick = () => {
        const { clickHandler } = this.props;

        clickHandler({ isIntake: true });
    };

    handleRequisitionClick = () => {
        const { clickHandler } = this.props;

        clickHandler({ isRequisition: true });
    };

    renderColumn = (data, i) => {
        return (
            <div className={classnames('col-xs-5', i === 0 && 'col-xs-offset-1')} key={i}>
                {this.renderOption(data)}
            </div>
        );
    };

    renderOption = (options) => {
        const { disabled, icon, onClick, permissionText, permissionType, text } = options;

        const { disabledIconButton, iconButton, iconText } = this.styles;

        const option = (
            <div>
                <Button
                    className={disabled ? disabledIconButton : iconButton}
                    onClick={disabled ? undefined : onClick}
                    qaTag={`projectCreateModal-${text}`}
                >
                    <i className={`fa fa-${icon} fa-4x`} />
                    <div className={iconText}>{text}</div>
                </Button>
                {disabled && (
                    <div>
                        <small className="text-danger">
                            <em>*Permission Required</em>
                        </small>
                        <br />
                        <AdminUserListButton
                            permissionType={permissionType}
                            text={permissionText}
                        />
                    </div>
                )}
            </div>
        );

        return option;
    };

    renderOptions() {
        const {
            isContractAdmin,
            isProjectCreator,
            hasContracting,
            hasIntake,
            hasProject,
            hasRequisition,
        } = this.props;

        const options = [];

        if (hasIntake) {
            options.push({
                icon: 'rss',
                onClick: this.handleIntakeClick,
                text: 'New Intake',
            });
        }

        if (hasProject) {
            options.push({
                disabled: !isProjectCreator,
                icon: 'folder-open',
                onClick: this.handlePurchaseClick,
                permissionText: 'Show project creators',
                permissionType: IS_PROJECT_CREATOR,
                text: 'New Project',
            });
        }

        if (hasRequisition) {
            options.push({
                disabled: false, // REQ TODO: Consider adding disable logic
                icon: 'shopping-cart',
                onClick: this.handleRequisitionClick,
                text: 'New Requisition',
            });
        }

        if (hasContracting) {
            options.push({
                disabled: !isContractAdmin,
                icon: 'file-text',
                onClick: this.handleContractClick,
                permissionText: 'Show contract admins',
                permissionType: IS_CONTRACT_ADMIN,
                text: 'New Contract Record',
            });
        }

        return this.renderOptionItems(options);
    }

    // Assumption is there will only be 2-6 options. Need to update if more options added.
    renderOptionItems(options) {
        const firstRow = options.slice(0, 2);
        const secondRow = options.slice(2, 4);
        const thirdRow = options.slice(4, 6);

        if (options.length === 3) {
            return (
                <div className="row">
                    {options.map((data, index) => (
                        <div className="col-xs-4" key={index}>
                            {this.renderOption(data)}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div>
                <div className="row">{firstRow.map(this.renderColumn)}</div>
                {secondRow.length > 0 && (
                    <div className={`row ${this.styles.additionalRow}`}>
                        {secondRow.map(this.renderColumn)}
                    </div>
                )}
                {thirdRow.length > 0 && (
                    <div className={`row ${this.styles.additionalRow}`}>
                        {thirdRow.map(this.renderColumn)}
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { projectCreateIcons, titleText } = this.styles;

        return (
            <div className={projectCreateIcons}>
                <div className={titleText}>What Would You Like to Do?</div>
                {this.renderOptions()}
            </div>
        );
    }
}
