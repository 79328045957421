import { fieldsTypes } from '@og-pro/shared-config/customFormService/customField';

import * as validateFieldTypeOptions from '../FieldTypeOptions/fieldTypeOptionsValidations';
import { ShortTextOptions } from '../FieldTypeOptions/ShortTextOptions';
import { LongTextOptions } from '../FieldTypeOptions/LongTextOptions';
import { NumberOptions } from '../FieldTypeOptions/NumberOptions';
import { DateOptions } from '../FieldTypeOptions/DateOptions';
import { DropdownOptions } from '../FieldTypeOptions/DropdownOptions';
import { CheckboxOptions } from '../FieldTypeOptions/CheckboxOptions';
import { YesNoOptions } from '../FieldTypeOptions/YesNoOptions';
import { MultipleChoiceOptions } from '../FieldTypeOptions/MultipleChoiceOptions';
import { ShortTextPreview } from '../FieldTypePreview/ShortTextPreview';
import { LongTextPreview } from '../FieldTypePreview/LongTextPreview';
import { NumberPreview } from '../FieldTypePreview/NumberPreview';
import { DatePreview } from '../FieldTypePreview/DatePreview';
import { DropdownPreview } from '../FieldTypePreview/DropdownPreview';
import { CheckboxPreview } from '../FieldTypePreview/CheckboxPreview';
import { YesNoPreview } from '../FieldTypePreview/YesNoPreview';
import { MultipleChoicePreview } from '../FieldTypePreview/MultipleChoicePreview';

const { SHORT_TEXT, LONG_TEXT, NUMBER, DATE, DROPDOWN, CHECKBOX, YES_NO, MULTIPLE_CHOICE } =
    fieldsTypes;

export const fieldTypesData = {
    [SHORT_TEXT]: {
        validateFn: validateFieldTypeOptions.validateShortTextOptions,
        optionsComponent: ShortTextOptions,
        previewComponent: ShortTextPreview,
    },
    [LONG_TEXT]: {
        validateFn: validateFieldTypeOptions.validateLongTextOptions,
        optionsComponent: LongTextOptions,
        previewComponent: LongTextPreview,
    },
    [NUMBER]: {
        validateFn: validateFieldTypeOptions.validateNumberOptions,
        optionsComponent: NumberOptions,
        previewComponent: NumberPreview,
    },
    [DATE]: {
        validateFn: validateFieldTypeOptions.validateDateOptions,
        optionsComponent: DateOptions,
        previewComponent: DatePreview,
    },
    [DROPDOWN]: {
        validateFn: validateFieldTypeOptions.validateDropdownOptions,
        optionsComponent: DropdownOptions,
        previewComponent: DropdownPreview,
    },
    [CHECKBOX]: {
        validateFn: validateFieldTypeOptions.validateCheckboxOptions,
        optionsComponent: CheckboxOptions,
        previewComponent: CheckboxPreview,
    },
    [YES_NO]: {
        validateFn: validateFieldTypeOptions.validateYesNoOptions,
        optionsComponent: YesNoOptions,
        previewComponent: YesNoPreview,
    },
    [MULTIPLE_CHOICE]: {
        validateFn: validateFieldTypeOptions.validateMultipleChoiceOptions,
        optionsComponent: MultipleChoiceOptions,
        previewComponent: MultipleChoicePreview,
    },
};
