import React from 'react';
import PropTypes from 'prop-types';
import { fieldsTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { fieldTypesData } from '../Form/constants';

export const FieldTypeOptions = ({ fieldType }) => {
    const FieldTypeOptionsComponent = fieldTypesData[fieldType].optionsComponent;

    if (!FieldTypeOptionsComponent) {
        return null;
    }

    return <FieldTypeOptionsComponent />;
};

FieldTypeOptions.propTypes = {
    fieldType: PropTypes.oneOf(fieldsTypesArray).isRequired,
};
